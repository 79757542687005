import React from "react"
import Layout from '../components/Layout';
import { Container, ButtonLink } from "../components/visual";
import * as styles from './404.module.css';

const NotFoundPage = () => (
  <Layout>
    <Container fitHeight>
      <div className={styles.page}>
        <div className={styles.number}><span className={styles.numberLetter}>4</span><span className={styles.numberLetter}>0</span><span className={styles.numberLetter}>4</span></div>
        <div className={styles.text}>Stránka sa nenašla </div>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
